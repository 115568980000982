import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

declare var $:any;

@Component({
  selector: 'combo-data-select',
  templateUrl: './combo-data-select.component.html',
  styleUrls: ['./combo-data-select.component.scss']
})
export class ComboDataSelectComponent implements OnInit,OnChanges {

  @Input("label") label = null;
  @Input("ngModel") value = null; 
  @Input("text") text   = null;
  @Input("data") data   = [];
  @ViewChild("select") select:ElementRef; 
  @Input("search") search = true;
  @Output("changeValue") changeValue = new EventEmitter();
  public comboCtrl        = new FormControl();
  public comboFilter:Observable<any>;
  public loader         = false;
  public response       = {
    data: [],
    status: null,
    message: ""
  };
  @Input("paramsAtributos") paramsAtributos = {
    text: "text",
    value: "value"
  }
  
  constructor(
    private api:ApiService,
    private app: AppService
  ){}

   /**
   * 
   * Get dataItem
   * 
   */
  _getDataItem(){

    let data = null; 

    for(let index = 0; index < this.data.length; index++) {

      if(this.value == this.data[index]["value"]){  

        data = this.data[index];
        break;

      }
      
    }

    return data;

  }
  /***
   * 
   * Verifica a mudança
   * 
   */
  _change(){

    try{
      
      let item = this._getDataItem();
      
      if(item != null){
        this.changeValue.emit(item);
      }else{
        this.changeValue.emit({
          value: null,
          text: null
        });
      }
      
    }catch(e){
        alert(e.message)
    }

  }
  /**
  * 
  * 
  * 
  */
  _getData(){
    
    if(this.response.status != 1){
      $(this.select.nativeElement).select2("close");
    }

  }
  initSelect(){

    let self = this;
    
    $(this.select.nativeElement).val(this.value).select2({
      allowClear: false,
      minimumResultsForSearch: this.search == true ? 1 : -1 
    });
    $(this.select.nativeElement).on("select2:select", function (e) {

      let value = $(this).val();        

      self.value = value;
      self._change();  
  
    });

  }
  /**
   * 
   * Inicializando as Funções
   * 
   */
  ngOnInit(){}
  /**
   * 
   * Init after view
   * 
   */
  ngAfterViewInit(): void {
    this.initSelect();
  }
   /**
   * 
   * Changes
   * 
   * @param changes
   * 
   */
    ngOnChanges(changes:any): void {

      if(changes.value){ 
        this.value = changes.value.currentValue;
        setTimeout(() => {
          this.initSelect();
        },500);
      }
  
    }

}
